import React, { Component } from 'react';
import Layout from '../components/organisms/Layout';
import SEO from '../components/molecules/SEO';
import styled from 'styled-components';

const Holder = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
  border-top: 0.5px solid;
  padding-top: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2fr 10fr;
  }
  > :nth-child(even) {
    margin-bottom: 3rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      width: 60%;
    }
  }
  > * {
    margin: 0;
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

class Contact extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact"/>
        <Holder>
          <h2>Email</h2>
          <p><a href="mailto:info@trentwhitehead.com">info@trentwhitehead.com</a></p>
          <h2>Instagram</h2>
          <p>
            <a href="https://www.instagram.com/trentburgerwhitehead/" target="_blank" rel="noopener noreferrer">@trentburgerwhitehead</a>
          </p>
          <h2>Mailing list</h2>
          <p>
            <a href="mailto:info@trentwhitehead.com?subject=Subscribe me&body=Hi Trent, Please subscribe me to your newsletter. Thanks.">info@trentwhitehead.com</a>
          </p>
        </Holder>
      </Layout>
    )
  }
}

export default Contact;